import React from 'react';
import PropTypes from 'prop-types';
import Section from '../section/';
import imageBlockStyles from './image-block.module.scss';

const ImageBlock = props => {
  const {
    node: { backgroundColor, images, imageAltText },
    oneImage,
  } = props;

  if (oneImage) {
    return (
      <div>
        {images &&
          images.map(image => (
            <div className={imageBlockStyles.oneImageContainer} key={image.id}>
              <img className={imageBlockStyles.image} src={image.file.url} alt={imageAltText} />
            </div>
          ))}
      </div>
    );
  }

  return (
    <Section backgroundColor={backgroundColor}>
      <div className={imageBlockStyles.multiImageContainer}>
        {images &&
          images.map(image => (
            <div key={image.id}>
              <img className={imageBlockStyles.image} src={image.file.url} alt={imageAltText} />
            </div>
          ))}
      </div>
    </Section>
  );
};

ImageBlock.propTypes = {
  /** pass this explicitly in order to render one image */
  oneImage: PropTypes.bool,
  node: PropTypes.shape({
    imageAltText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      id: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string,
        fileName: PropTypes.string,
        contentType: PropTypes.string,
      }),
    }),
  }).isRequired,
};

ImageBlock.defaultProps = {
  oneImage: null,
};

export default ImageBlock;
