import React from 'react';
import ReactMarkdown from 'react-markdown';
import Section from '../section';
import { ButtonLink } from '../button';
import PanelHeadline from '../panel-headline/';
import highlightPanelStyles from '../highlight-panel/highlight-panel.module.scss';
import { highlightPanelPropType } from '../../prop-types/';

/* These two functions allow text and image elements to be positioned depending
   on the value of the 'imageMiddleTextTop' and/or 'imageBottom' boolean */
const placeImageRight = imageMiddleTextTop =>
  !imageMiddleTextTop && highlightPanelStyles.imageRight;

const placeImageMiddleOrBottom = (imageMiddleTextTop, imageBottom) =>
  imageMiddleTextTop && !imageBottom
    ? highlightPanelStyles.imageMiddle
    : highlightPanelStyles.imageBottom;

/* Allows different classNames to be added to certain elements
   depending on the value of the 'imageMiddleTextTop' boolean- to position images
   and text correctly according to the designs */
const getElementAlignmentModifierClass = (baseClass, imageMiddleTextTop) =>
  imageMiddleTextTop
    ? highlightPanelStyles[`${baseClass}TextTop`]
    : highlightPanelStyles[`${baseClass}TextLeft`];

const HighlightPanel = ({
  node: {
    backgroundColor,
    headline,
    headlineColor,
    text,
    textColor,
    highlightButton,
    imageMiddleTextTop,
    imageBottom,
    images,
    imageAltText,
    isSimpleHeadline,
  },
}) => (
  <Section backgroundColor={backgroundColor}>
    <div className={getElementAlignmentModifierClass('container', imageBottom)}>
      <div className={highlightPanelStyles.wrapper}>
        <div className={getElementAlignmentModifierClass('containerItems', imageMiddleTextTop)}>
          {isSimpleHeadline ? (
            <PanelHeadline simple headline={headline} headlineColor={headlineColor} />
          ) : (
            <h2
              className={getElementAlignmentModifierClass('header', imageMiddleTextTop)}
              style={{ color: headlineColor }}
            >
              {headline}
            </h2>
          )}
          {images &&
            !imageMiddleTextTop &&
            images.map(image => (
              <div
                className={getElementAlignmentModifierClass('imageWrapper', imageMiddleTextTop)}
                key={image.id}
              >
                <img
                  className={placeImageRight(imageMiddleTextTop)}
                  src={image.file.url}
                  alt={imageAltText}
                />
              </div>
            ))}
          <div
            className={getElementAlignmentModifierClass('text', imageMiddleTextTop)}
            style={{ color: textColor }}
          >
            <ReactMarkdown source={text.text} />
          </div>
          {images &&
            imageMiddleTextTop &&
            images.map(image => (
              <div
                className={getElementAlignmentModifierClass('imageWrapper', imageMiddleTextTop)}
                key={image.id}
              >
                <img
                  className={placeImageMiddleOrBottom(imageMiddleTextTop, imageBottom)}
                  src={image.file.url}
                  alt={imageAltText}
                />
              </div>
            ))}
          <div className={highlightPanelStyles.button}>
            {highlightButton && (
              <ButtonLink
                type="primary"
                href={highlightButton.url}
                backgroundColor={highlightButton.backgroundColor}
                color={highlightButton.textColor}
              >
                {highlightButton.text}
              </ButtonLink>
            )}
          </div>
        </div>
      </div>
    </div>
  </Section>
);

HighlightPanel.propTypes = {
  node: highlightPanelPropType.isRequired,
};

export default HighlightPanel;
