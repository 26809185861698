import React from 'react';
import Section from '../section';
import { ButtonLink } from '../button';
import PanelHeadline from '../panel-headline';
import signUpPanelStyles from '../sign-up-panel/sign-up-panel.module.scss';
import { signUpPanelPropType } from '../../prop-types/';

const SignUpPanel = ({
  node: {
    backgroundColor,
    headline,
    headlineColor,
    panelHeadline,
    panelHeadlineColor,
    textBackgroundColor,
    imageBackgroundColor,
    discounttrialBadgeTextColor,
    discounttrialBadgeBackgroundColor,
    discounttrialBadge,
    signUpButton,
    image,
    imageAltText,
    bulletPointList,
  },
}) => (
  <Section type="noPadding" backgroundColor={backgroundColor}>
    <div className={signUpPanelStyles.wrapper}>
      <div className={signUpPanelStyles.headline}>
        <PanelHeadline simple headline={headline} headlineColor={headlineColor} />
      </div>
      <div className={signUpPanelStyles.contentContainer}>
        <div
          className={signUpPanelStyles.imageContainer}
          style={{ backgroundColor: imageBackgroundColor }}
        >
          <div
            className={signUpPanelStyles.discountBadge}
            style={{
              backgroundColor: discounttrialBadgeBackgroundColor,
              color: discounttrialBadgeTextColor,
            }}
          >
            {discounttrialBadge}
          </div>
          <img className={signUpPanelStyles.image} src={image.file.url} alt={imageAltText} />
        </div>
        <div
          className={signUpPanelStyles.textContainer}
          style={{ backgroundColor: textBackgroundColor }}
        >
          <h2 className={signUpPanelStyles.sectionHeadline} style={{ color: panelHeadlineColor }}>
            {panelHeadline}
          </h2>
          <ul className={signUpPanelStyles.list}>
            {bulletPointList &&
              bulletPointList.bulletPoints.map(bullet => (
                <li
                  className={signUpPanelStyles.bullet}
                  style={{ color: bullet.textColor }}
                  key={bullet.id}
                >
                  <img
                    className={signUpPanelStyles.bulletImage}
                    src={bullet.image.file.url}
                    alt={bullet.imageAltText}
                  />
                  {bullet.text}
                </li>
              ))}
          </ul>
          <ButtonLink
            type="primary"
            href={signUpButton.url}
            backgroundColor={signUpButton.backgroundColor}
            color={signUpButton.textColor}
          >
            {signUpButton.text}
          </ButtonLink>
        </div>
      </div>
    </div>
  </Section>
);

SignUpPanel.propTypes = {
  node: signUpPanelPropType.isRequired,
};

export default SignUpPanel;
