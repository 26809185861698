import React from 'react';
import PropTypes from 'prop-types';
import panelHeadlineStyles from './panel-headline.module.scss';

const PanelHeadline = props => {
  const { headline, headlineColor, text, textColor, simple } = props;

  if (simple) {
    return (
      <div className={panelHeadlineStyles.container}>
        <h2 className={panelHeadlineStyles.simpleHeadline} style={{ color: headlineColor }}>
          {headline}
        </h2>
      </div>
    );
  }

  return (
    <div className={panelHeadlineStyles.container}>
      <h2 className={panelHeadlineStyles.headline} style={{ color: headlineColor }}>
        {headline}
      </h2>
      <p className={panelHeadlineStyles.leadText} style={{ color: textColor }}>
        {text}
      </p>
    </div>
  );
};

PanelHeadline.propTypes = {
  /** pass this explicitly in order to render a simple headline with no leadText */
  simple: PropTypes.bool,
  /** content rendered inside header element */
  headline: PropTypes.string,
  /** color of content rendered inside header element */
  headlineColor: PropTypes.string,
  /** content rendered below header element */
  text: PropTypes.string,
  /** color of content rendered below header element */
  textColor: PropTypes.string,
};

PanelHeadline.defaultProps = {
  simple: null,
  text: '',
  headline: '',
  headlineColor: '',
  textColor: '',
};

export default PanelHeadline;
