import React from 'react';
import PropTypes from 'prop-types';
import Section from '../section/';
import LogoBox from '../logo-box/';
import PanelHeadline from '../panel-headline';
import { logoBoxPropType } from '../../prop-types';
import logoWallStyles from './logo-wall.module.scss';

const LogoWall = props => {
  const {
    node: { backgroundColor, headline, headlineColor, logoBoxes },
  } = props;
  return (
    <Section backgroundColor={backgroundColor}>
      <PanelHeadline simple headline={headline} headlineColor={headlineColor} />
      <div className={logoWallStyles.imageContainer}>
        {logoBoxes &&
          logoBoxes.map(logoBox => (
            <div
              key={logoBox.image.file.fileName}
              className={logoWallStyles.logoBox}
              style={{ backgroundColor: logoBox.backgroundColor }}
            >
              <LogoBox className={logoWallStyles.image} logoBox={logoBox} />
            </div>
          ))}
      </div>
    </Section>
  );
};

LogoWall.propTypes = {
  node: PropTypes.shape({
    backgroundColor: PropTypes.string,
    headline: PropTypes.string,
    headlineColor: PropTypes.string,
    logoBoxes: PropTypes.arrayOf(logoBoxPropType),
  }).isRequired,
};

export default LogoWall;
