import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Section from '../section';
import textBlockStyles from './text-block.module.scss';

const TextBlock = ({ node: { backgroundColor, headline, headlineColor, text, textColor } }) => (
  <Section backgroundColor={backgroundColor}>
    <div className={textBlockStyles.wrapper}>
      <h2 className={textBlockStyles.header} style={{ color: headlineColor }}>
        {headline}
      </h2>
      <div className={textBlockStyles.text} style={{ color: textColor }}>
        <ReactMarkdown source={text.text} />
      </div>
    </div>
  </Section>
);

TextBlock.propTypes = {
  node: PropTypes.shape({
    backgroundColor: PropTypes.string,
    headline: PropTypes.string,
    headlineColor: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    textColor: PropTypes.string,
  }).isRequired,
};

export default TextBlock;
