import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import head from 'lodash.head';
import Layout from '../components/layout';
import Header from '../components/header';
import TextBlock from '../components/text-block/';
import ImageBlock from '../components/image-block/';
import HighlightPanel from '../components/highlight-panel/';
import LogoWall from '../components/logo-wall/';
import SignUpPanel from '../components/sign-up-panel/';
import Footer from '../components/footer';

const AboutPage = () => (
  <StaticQuery
    query={graphql`
      query aboutPage {
        allContentfulAboutPage {
          edges {
            node {
              navigationBackgroundColor
              seo {
                seoTitle
                seoKeywords {
                  seoKeywords
                }
                seoDescription {
                  seoDescription
                }
              }
              header {
                title
                backgroundColor
                headline
                headlineColor
                text {
                  text
                }
                textColor
                imageAltText
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                mobileImage {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
              textBlock1 {
                backgroundColor
                headline
                headlineColor
                text {
                  text
                }
                textColor
              }
              textBlock2 {
                backgroundColor
                headline
                headlineColor
                text {
                  text
                }
                textColor
              }
              imageBlock1 {
                backgroundColor
                imageAltText
                images {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
              imageBlock2 {
                backgroundColor
                imageAltText
                images {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
              highlightPanel {
                backgroundColor
                title
                headline
                headlineColor
                isSimpleHeadline
                textColor
                text {
                  text
                }
                imageMiddleTextTop
                imageBottom
                imageAltText
                images {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
              logoWall {
                backgroundColor
                headline
                headlineColor
                logoBoxes {
                  backgroundColor
                  imageAltText
                  image {
                    file {
                      url
                      fileName
                      contentType
                    }
                  }
                }
              }
              signUpPanel {
                signUpButton {
                  text
                  textColor
                  backgroundColor
                  url
                }
                backgroundColor
                headline
                headlineColor
                panelHeadline
                panelHeadlineColor
                textBackgroundColor
                imageBackgroundColor
                discounttrialBadge
                discounttrialBadgeTextColor
                discounttrialBadgeBackgroundColor
                imageAltText
                image {
                  file {
                    url
                    fileName
                    contentType
                  }
                }
                bulletPointList {
                  id
                  bulletPoints {
                    id
                    text
                    textColor
                    imageAltText
                    image {
                      file {
                        url
                        fileName
                        contentType
                      }
                    }
                  }
                }
              }
              footer {
                id
                links {
                  id
                  externalUrl
                  slug
                  displayName
                }
                logo {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allContentfulAboutPage: { edges } }) => {
      const {
        navigationBackgroundColor,
        seo,
        header,
        textBlock1,
        imageBlock1,
        textBlock2,
        imageBlock2,
        highlightPanel,
        logoWall,
        signUpPanel,
        footer,
      } = head(edges).node;
      return (
        <Layout
          seoTitle={seo.seoTitle}
          seoDescription={seo.seoDescription.seoDescription}
          seoKeywords={seo.seoDescription.seoDescription}
          navigationBackgroundColor={navigationBackgroundColor}
        >
          <Header node={header} />
          <TextBlock node={textBlock1} />
          <ImageBlock node={imageBlock1} oneImage />
          <TextBlock node={textBlock2} />
          <ImageBlock node={imageBlock2} />
          <HighlightPanel node={highlightPanel} />
          <LogoWall node={logoWall} />
          <SignUpPanel node={signUpPanel} />
          <Footer node={footer} />
        </Layout>
      );
    }}
  />
);

export default AboutPage;
