import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Section from '../section';
import headerStyles from './header.module.scss';

const Header = ({
  node: {
    backgroundColor,
    headline,
    headlineColor,
    text,
    textColor,
    image,
    imageAltText,
    mobileImage,
  },
}) => (
  <Section backgroundColor={backgroundColor}>
    <div className={headerStyles.wrapper}>
      <div className={headerStyles.imageWrapper}>
        <img className={headerStyles.imageDesktopImage} src={image.file.url} alt={imageAltText} />
        {mobileImage && (
          <img
            className={headerStyles.imageMobileImage}
            src={mobileImage.file.url}
            alt={imageAltText}
          />
        )}
      </div>
      <div className={headerStyles.containerItems}>
        <h1 className={headerStyles.header} style={{ color: headlineColor }}>
          {headline}
        </h1>
        <div className={headerStyles.text} style={{ color: textColor }}>
          <ReactMarkdown source={text.text} />
        </div>
      </div>
    </div>
  </Section>
);

Header.propTypes = {
  node: PropTypes.shape({
    title: PropTypes.string,
    backgroundColor: PropTypes.string,
    headline: PropTypes.string,
    headlineColor: PropTypes.string,
    text: PropTypes.shape({
      text: PropTypes.string,
    }),
    textColor: PropTypes.string,
    imageAltText: PropTypes.string,
    image: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
        fileName: PropTypes.string,
        contentType: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default Header;
